<template>
  <div class="form_wrap">
    <div class="head">
      选择:(请先选择年级<span v-if="showClass">/班级</span>和科目)
    </div>
    <div style="padding-top:10px"
         v-if="JSON.stringify(form)!='{}'">
      <el-form :model="form"
               ref="formRef"
               :rules="rules"
               label-width="90px">
        <!--  -->
        <el-row :gutter="10">
          <span class="title">试卷设置：</span>
        </el-row>
        <el-row :gutter="10"
                style="border-bottom:1px solid #eee">
          <el-col :span="8">
            <el-form-item label="试卷名称">
              <el-input clearable
                        placeholder="请输入"
                        style="width:100%"
                        v-model="form.paper_name" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- <el-form-item label="指定年级"
                          prop="grade_id">
              <el-select v-model="form.grade_id"
                         style="width:100%"
                         @change="gradeChange"
                         placeholder="选择">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="指定年级"
                          prop="grade_id">
              <el-radio-group v-model="form.grade_id"
                              style="width:100%"
                              @change="gradeChange"
                              placeholder="选择">
                <el-radio v-for="item in gradeList"
                          :key="item.grade_id"
                          :label="item.grade_id">{{item.grade_name}}</el-radio>

              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8"
                  v-if="showClass">
            <el-form-item label="指定班级"
                          prop="class_id">
              <el-select v-model="form.class_id"
                         style="width:100%"
                         clearable
                         @change="classChange"
                         placeholder="选择">
                <el-option v-for="item in classList"
                           :key="item.id"
                           :label="item.class_name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="试卷科目"
                          prop="questionSubject">
              <el-select v-model="form.questionSubject"
                         @change="changeSubject"
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- prop="question_year_begin" -->
          <el-col :span="8">
            <el-form-item label="试卷地区">
              <el-select v-model="form.district_id"
                         style="width:100%"
                         multiple
                         clearable
                         filterable
                         placeholder="选择(默认所有)">
                <el-option v-for="item in testingList"
                           :disabled="(item.paper_district_id=='12'&&form.district_id.length>0)||(form.district_id.indexOf(12)!=-1&&item.paper_district_id!='12')"
                           :key="item.paper_district_id"
                           :label="item.paper_district_name"
                           :value="item.paper_district_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="题目来源"
                          prop="source_id">
              <el-select v-model="form.source_id"
                         style="width:100%"
                         filterable
                         placeholder="选择(默认所有)">
                <el-option v-for="item in sourceList"
                           :key="item.source_id"
                           :label="item.source_name"
                           :value="item.source_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开始年份"
                          prop="question_year_begin">
              <el-date-picker v-model="form.question_year_begin"
                              style="width:100%"
                              value-format="yyyy"
                              type="year"
                              :picker-options="pickerOptions"
                              placeholder="选择年">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="截止年份"
                          prop="question_year_end">
              <el-date-picker v-model="form.question_year_end"
                              style="width:100%"
                              value-format="yyyy"
                              type="year"
                              placeholder="选择年">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <span class="title">题目设置：</span>
        </el-row>
        <el-row :gutter="10"
                style="border-bottom:1px solid #eee">
          <el-col :span="8">
            <!--非英语的时候显示类型 -->
            <el-form-item label="题目类型">
              <el-select v-model="form.questionType"
                         style="width:100%"
                         multiple
                         @change='changeType'
                         placeholder="选择">
                <el-option v-for="item in typeList"
                           :key="item.question_type_id"
                           :label="item.question_type_name"
                           :value="item.question_type_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8"
                  class="question_number">
            <el-form-item label="题量设置"
                          prop="questionNumber">
              <el-input clearable
                        :id="'numberInput'+randomId"
                        v-model="form.questionNumber"
                        style="width:100%"
                        suffix-icon="el-icon-arrow-down"
                        readonly
                        @focus="showQuestionNumber" />
              <!-- :readonly="(form.type_id&&form.type_id.length>0)||(form.category_id&&form.category_id.length>0) -->
              <div :id="'numberComponent'+randomId"
                   v-show="isShowQuestionNumber">
                <div class="question_number_arrow">
                </div>
                <div class="question_number_select"
                     id="question_number_select1">
                  <div v-show='!form.questionType||form.questionType.length<=0'
                       style='width:150px;text-align:center;color:#ccc'>先设置题目类型</div>
                  <div v-for="item,index in form.questionType"
                       style="margin-bottom:5px"
                       :key='index'>
                    {{(typeList.filter(n=>n.question_type_id==item))[0]['question_type_name']}}
                    <el-input-number v-model="questionNumberDetail[item]"
                                     style="width:120px"
                                     :min='1'
                                     :max="50"
                                     controls-position="right" /><br>
                  </div>
                </div>
                <!-- <div class="question_number_select"
                     id="question_number_select2"
                     v-show="show">
                  <div v-show='!form.questionCategory||form.questionCategory.length<=0'
                       style='width:150px;text-align:center;color:#ccc'>先设置题目类别</div>
                  <div v-for="item,index in form.questionCategory"
                       style="margin-bottom:5px"
                       :key='index'>
                    {{(categoriesList.filter(n=>n.question_category_id==item))[0]['question_category_name']}}
                    <el-input-number v-model="questionNumberDetail[item]"
                                     style="width:120px"
                                     :min='1'
                                     controls-position="right" /><br>
                  </div>
                </div> -->
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8"
                  v-if="show">
            <!-- 类别只有当科目为英语的时候才显示 -->
            <el-form-item label="题目类别">
              <el-select v-model="form.questionCategory"
                         style="width:100%"
                         multiple
                         placeholder="选择"
                         @change='changeType'>
                <el-option v-for="item in categoriesList"
                           :key="item.question_category_id"
                           :label="item.question_category_name"
                           :value="item.question_category_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="考查范围"
                          prop="select_which">
              <el-radio-group v-model="form.select_which"
                              @change="changeRange">
                <el-radio :label="index"
                          @click.native.prevent="handleRange(index)"
                          v-for="item,index in rangeList"
                          :key="index">{{item}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="8"
                  v-if="form.select_which=='1' ">
            <!--form.grade_id==2||form.grade_id==3||grade_id==2||grade_id==3-->
            <el-form-item label="考点"
                          prop="knowledge_id">
              <div class="zhishidian">
                <el-cascader v-model="knowledge_ids"
                             ref="cascaderRef"
                             :options="knowledgeList"
                             :props="props"
                             :key='randomId'
                             placeholder="选择"
                             @change="changeKnowledge"
                             collapse-tags
                             filterable></el-cascader>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8"
                  v-if="form.select_which=='2'">
            <el-form-item label="教材">
              <el-select v-model="form.textbook_id"
                         style="width:100%"
                         clearable
                         filterable
                         @change="getChapterList"
                         placeholder="选择">
                <el-option v-for="item in bookList"
                           :key="item.textbook_id"
                           :label="item.textbook_name"
                           :value="item.textbook_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8"
                  v-if="form.select_which=='2'">
            <el-form-item label="章节"
                          prop="chapter_id">
              <div class="zhishidian">
                <el-cascader v-model="chapter_ids"
                             ref="cascaderChapterRef"
                             :options="sectionList"
                             :props="propsChapter"
                             :key="randomId+'chapter'"
                             placeholder="选择"
                             @change="changeChapter"
                             collapse-tags
                             filterable></el-cascader>
              </div>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="8"
                  v-show="form.select_which=='4'&& form.questionSubject!='7' && form.questionSubject != '11'"
                  class="">

            <el-form-item label="关键能力"
                          prop="capability_type1">
              <el-cascader v-model="form.capability_type1"
                           style="width: 100%"
                           :options="cascaderOptions"
                           :props="{ value: 'value', label: 'label',emitPath:true, children: 'children', multiple: true, }"
                           clearable
                           collapse-tags
                           placeholder="选择"></el-cascader>
            </el-form-item>
          </el-col> -->
          <el-col :span="8"
                  v-if="form.select_which=='3'"
                  class="">

            <el-form-item label="核心素养"
                          prop="capability_id">
              <el-cascader v-model="form.capability_id"
                           style="width:100%"
                           collapse-tags
                           :options="cascaderOptions2"
                           :props="{ value: 'value', label: 'label',emitPath:true, children: 'children', multiple: true, }"
                           clearable
                           placeholder="选择">
              </el-cascader>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="题目难度">
              <el-select v-model="form.question_difficulty"
                         style="width:100%"
                         clearable
                         placeholder="选择">
                <el-option v-for="(item,index) in difficultyList"
                           :key="index"
                           :label="item.text"
                           :value="item.val">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8"
                  v-if=" form.questionSubject=='3' || form.questionSubject == '1'">

            <el-form-item label="是否推送整题"
                          label-width="115px"
                          prop="select_all">
              <el-checkbox v-model="form.select_all">是</el-checkbox>
            </el-form-item>
          </el-col>

          <el-col :span="24"
                  v-show="tipList&&tipList.length>0">
            <el-row :gutter="10"
                    style="margin:5px 10px 10px ">
              <div style="width:100%;font-size:15px;padding-left:12px">已选{{tipContent}}</div>
              <span v-for="item,index in tipList"
                    :key="index"
                    style="padding:5px">
                <el-tag closable
                        @close="closeTag(index)">{{item}}</el-tag>
              </span>
            </el-row>
          </el-col>

          <el-col :span="24"
                  v-show="points2TipList&&points2TipList.length>0">
            <el-row :gutter="10"
                    style="margin:5px 10px 10px ">
              <div style="width:100%;font-size:15px;padding-left:12px">已选二级考点</div>
              <span v-for="item,index in points2TipList"
                    :key="index"
                    style="padding:5px">
                <el-tag closable
                        @close="closePoint2Tag(index)">{{item}}</el-tag>
              </span>
            </el-row>
          </el-col>
          <el-col :span="24"
                  v-show="points3TipList&&points3TipList.length>0">
            <el-row :gutter="10"
                    style="margin:5px 10px 10px ">
              <div style="width:100%;font-size:15px;padding-left:12px">已选三级考点</div>
              <span v-for="item,index in points3TipList"
                    :key="index"
                    style="padding:5px">
                <el-tag closable
                        @close="closePoint3Tag(index)">{{item}}</el-tag>
              </span>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <span class="title">做卷设置：</span>
        </el-row>
        <el-row :gutter="10">

          <el-col :span="8">
            <el-form-item label="试卷时长">
              <el-input v-model="form.paper_duration"
                        maxlength="4"
                        placeholder="试卷时长(分钟)"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="开始时间">
              <el-date-picker v-model="form.paper_time"
                              type="datetime"
                              style="width:100%"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="选择开始时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="截止时间">
              <el-date-picker v-model="form.end_time"
                              style="width:100%"
                              type="datetime"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              default-time="23:59:59"
                              placeholder="试卷截止时间" />
            </el-form-item>
          </el-col>

          <!-- <el-col :span="8">
            <el-form-item label="批改类型"
                          prop="question_year_begin">
              <el-radio-group v-model="form.edu_paper_correct_type">
                <el-radio :label="'1'">教师批改</el-radio>
                <el-radio :label="'2'">学生自评</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->

          <!-- 指定组卷没选学生时显示 -->
          <!-- <el-col :span="8"
                  v-show="isShow_to">
            <el-form-item label="试卷可见">
              <el-select v-model="form.show_to"
                         style="width:100%"
                         clearable
                         placeholder="选择">
                <el-option v-for="item in $showList"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>-->
        </el-row>

        <el-row style="margin-left:25px">
          <p style="width:100%">
            <span style="color:#f00;padding:0 4px 0 ">*</span>说明：
          </p>
          <ol>
            <!-- <li v-show="showClass">指定年级和指定班级二选一； </li> -->
            <li>
              试卷可以定时开始训练，也可以不选时间，这样学生任何时间都可以训练；
            </li>
            <li>
              组的试卷必须提交才能提供给学生做题。
            </li>
          </ol>
        </el-row>

        <el-row :gutter="10"
                style="text-align:center;margin-top:10px">
          <el-col :span="24">
            <!---->
            <el-button type="primary"
                       :loading="loadings"
                       @click="toDetails">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getGrade, getSubject } from '@/api/IntelligentTest.js'
import { getFlatlist, getParentIdList, getParentIds } from '@/utils/common.js'
import { getTextBookBySubject } from '@/api/textBook.js'
export default {
  /**
   * 修改年级/班级，科目数据重新加载；
   * 修改科目,试卷来源、题目类型/类别、考点/知识点重新加载，题量重新加载
   */
  data () {
    return {
      loadings: false,
      tipList: [], //已选知识点/考点/章节数据
      points2TipList: [],//已选二级考点
      points3TipList: [],//已选三级考点
      tipContent: '',
      // 仅当科目为英语时显示
      show: '',
      randomId: Math.random(),
      grade_id: '',//选班级时的年级
      form: {},
      questionNumberDetail: {},//题目数量信息{类型/类别id:数目}
      subjectList: [],
      gradeList: [],
      sourceList: [],
      knowledgeList: [],
      flatKnowledgeList: [],
      knowledge_ids: [],//直接用form.knowledge_id会出现有时选了没显示的bug，另建一个变量解决
      chapter_ids: [],
      sectionList: [],
      flatSectionList: [],
      testingList: [],//试卷地区列表
      resultCheckpointsList: [],
      checkPoint2Options: [],
      checkPoint3Options: [],
      difficultyList: [],
      typeList: [],
      categoriesList: [],
      paperTypeList: [],


      rules: {
        paper_name: [
          { required: 'true', message: '请填写试卷名称', trigger: 'change' }
        ],
        // district_id: [
        //   { required: 'true', message: '请选择', trigger: 'change' }
        // ],
        questionType: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        questionSubject: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        // source_id: [
        //   { required: 'true', message: '请选择', trigger: 'change' }
        // ],
        question_year_begin: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        question_year_end: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        select_which: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        check_point_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        capability_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        chapter_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        knowledge_id: [
          { required: true, message: '请选择', trigger: 'change' },

        ],

      },
      classList: [],
      bookList: [],
      cascaderOptions: [],
      cascaderOptions2: [],
      props: {
        multiple: true,
        children: 'child',
        value: 'knowledge_id',
        label: 'knowledge_desc',
        emitPath: false,// 只返回该节点的值

      },
      propsChapter: {
        multiple: true,
        children: 'child',
        value: 'chapter_id',
        label: 'chapter_desc',
        emitPath: false,// 只返回该节点的值

      },
      isShowQuestionNumber: false, //是否显示题目数目下拉框
    }
  },
  props: ['isShow_to', 'showClass'],
  created () {
    // this.getDistrictList()
  },
  mounted () {
    //日期控件限制
    this.pickerOptions = {
      disabledDate (time) {
        const currentYear = new Date().getFullYear();
        const currentDate = new Date().getDate();
        const august1st = new Date(currentYear, 7, 1); // 月份从0开始计数，所以这里是7表示8月

        if (currentDate > 1) { // 如果今天超过8月1日
          return time.getFullYear() > currentYear + 1;
        } else {
          return time.getFullYear() > currentYear;
        }
      }
    };
  },

  computed: {
    rangeList () {
      if (this.form.grade_id == 1 || this.grade_id == 1) {
        console.log('this,from', this.form);
        console.log('1年级', this.form.questionSubject);

        if (this.form.questionSubject == 1 || this.form.questionSubject == 3) {

          return {
            // '1': '考点',
            '1': '考点',
            '3': '核心素养'
          }

        } else {
          return {
            // '1': '考点',
            '1': '考点',
            '2': '章节',
            // '4': '关键能力',
            '3': '核心素养'
          }
        }

      } else if (this.form.grade_id == 2 || this.form.grade_id == 3 || this.grade_id == 2 || this.grade_id == 3) {
        if (this.form.questionSubject == 7 || this.form.questionSubject == 11) {
          return {
            '1': '考点',
            '3': '核心素养'
          }
        } else {
          return {
            '1': '考点',
            '2': '章节',
            // '4': '关键能力',
            '3': '核心素养'
          }
        }

      }
    },

  },
  watch: {
    'form.paper_duration': {
      handler (newVal, oldVal) {
        var numberarr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
        if (newVal != '' && numberarr.findIndex(item => item == newVal[newVal.length - 1]) == -1) {
          this.form.paper_duration = oldVal
        }
      },
      deep: true
    },
    'form.select_which': {
      handler (newVal, oldVal) {
        this.form.chapter_id = []
        this.form.knowledge_id = []
        this.form.checkpoints2 = []
        this.form.checkpoints3 = []
        this.form.check_point_id = []
      },
      deep: true
    },
    // 根据题目数量具体属性值计算题量总数
    'questionNumberDetail': {
      handler (newVal, oldVal) {
        if ((this.form.questionType && this.form.questionType.length > 0) || (this.form.questionCategory && this.form.questionCategory.length > 0)) {
          this.form.questionNumber = 0
          for (let i in newVal) {
            if (Number(newVal[i])) {
              this.form.questionNumber += newVal[i]
            }
          }
        } else {
          this.form.questionNumber = 5
        }
      },
      deep: true
    },
    'rangeList': {
      handler (newVal, oldVal) {

        this.changeRange()
        let temp

        if (!oldVal) {

          this.form.select_which = '1'
        }
      
        // console.log('9999fdsafdas', this.form.questionSubject)
        // this.$nextTick(() => {
        //   this.$set(this.form, 'select_which', temp)
        // })
      }, deep: true
    },

  },


  methods: {

    initData () {
      this.getDiffcultyList()
      this.getGradeList()
      this.getClassList()
      this.getDistrictList()
      this.getSourceList()
      this.getBookList()
      this.getCapabilitySubject()
      // this.getsectionList()
      // this.getCapabilityMain()
    },
    showQuestionNumber () {
      this.isShowQuestionNumber = true
      setTimeout(() => {
        window.addEventListener("click", this.hiddenQuestionNumber)
      }, 500)
    },
    hiddenQuestionNumber (e) {
      let btn = document.getElementById("numberInput" + this.randomId);
      let box = document.getElementById("numberComponent" + this.randomId);

      // 判断鼠标点击到触发按钮和弹出框外的区域
      if (btn && box && !box.contains(e.target) && !btn.contains(e.target)) {
        this.isShowQuestionNumber = false
        window.removeEventListener("click", this.hiddenQuestionNumber)
      }
    },


    /**题目类型/类别改变时，题量变化  */
    changeType (value) {
      // 直接使用value有时会出现value比实际多出一个或少一个的情况
      if (this.form.questionType.length > 0) {
        value = this.form.questionType
      } else if (this.form.questionCategory.length > 0) {
        value = this.form.questionCategory
      }
      for (let i of value) {
        if (!this.questionNumberDetail[i]) {
          this.$set(this.questionNumberDetail, i, 1)
        }
      }
      let key = Object.keys(this.questionNumberDetail)
      for (let i = 0; i < key.length; i++) {
        if (value.indexOf(Number(key[i])) == -1) {
          this.$set(this.questionNumberDetail, key[i], 0)
          delete this.questionNumberDetail[key[i]]
        }
      }

    },
    // 修改验证信息
    addRules () {
      // 不用显示班级的情况
      if (!this.showClass) {
        return
      }
      this.form = { ...this.form };//解决表单验证失效问题
      let rules = JSON.parse(JSON.stringify(this.rules))
      let class_id = [
        { required: true, message: '请选择', trigger: 'change' },
      ]
      let grade_id = [
        { required: true, message: '请选择', trigger: 'change' },
      ]
      if (!this.form.class_id && !this.form.grade_id) {
        rules.class_id = class_id
        rules.grade_id = grade_id
      } else if (this.form.grade_id) {
        delete rules.class_id
        rules.grade_id = grade_id
      } else if (this.form.class_id) {
        delete rules.grade_id
        rules.class_id = class_id
      }
      this.$set(this, 'rules', rules)
    },
    gradeChange () {
      this.form.class_id = ''
      // 当选择了班级
      if (this.form.grade_id == '') return
      this.addRules()
      let form = {
        grade_id: this.form.grade_id
      }
      this.getClassList()
      // this.form.questionSubject = ''
      this.getSubjectList(form)
      this.getChapterList()
      this.getKnowledgeList()
    },
    classChange () {
      // this.form.grade_id = ''
      // this.grade_id = ''
      // this.form.questionSubject = ''
      if (this.form.class_id == '') {
        return
      }
      this.addRules()
      // let form = {
      //   class_id: this.form.class_id
      // }
      // this.getSubjectList(form)
    },
    async getDistrictList () {
      await this.$http({
        url: '/api/v1/combination/region',
        method: 'get',
        params: {
          subject_id: this.form.questionSubject,
          grade_id: this.form.grade_id
        }
      }).then(res => {
        this.testingList = res.data.districtList
        // this.form.district_id = this.form.district_id || this.testingList[0].paper_district_id
        // if (!this.form.district_id || this.testingList.filter(item => item.paper_district_id == this.form.district_id).length == 0) {
        //   this.form.district_id = this.testingList[0].paper_district_id
        // }
        if (this.form.district_id.length) {
          let districtIds = []
          for (let i of this.testingList) {
            districtIds.push(i.paper_district_id)
          }
          this.form.district_id = this.form.district_id.filter(item => {
            return districtIds.includes(item)
          })
        }
        // difficultyList
        // this.getDiffcultyList()
        // this.getGradeList()
        // this.getClassList()

      })
    },
    getClassList () {
      this.$http({
        url: '/api/v1/special/tc_lead_class',
        params: {
          all_class: 1,
          grade_id: this.form.grade_id
        },
        method: 'get'
      }).then(res => {
        this.classList = res.data
      })
    },
    getCapabilityMain () {
      this.$http({
        url: '/api/v1/question/capabilities',
        method: 'get',
        params: {
          subject_id: this.form.questionSubject,
          capability_type_id: 1 //关键能力
        }
      }).then(res => {

        const formData = res.data.list.map(item => ({
          value: item.capability_id,
          label: item.capability_name,
          children: item.child ? item.child.map(child => ({
            value: child.capability_id,
            label: child.capability_name
          })) : ""
        }));
        this.cascaderOptions = formData;

      })
    },
    getCapabilitySubject () {
      this.$http({
        url: '/api/v1/question/capabilities',
        method: 'get',
        params: {
          subject_id: this.form.questionSubject,
          // capability_type_id: 2 //学科素养
        }
      }).then(res => {
        const formData = res.data.list.map(item => ({
          value: item.capability_id,
          label: item.capability_name,
          children: item.child ? item.child.map(child => ({
            value: child.capability_id,
            label: child.capability_name
          })) : ''
        }));
        this.cascaderOptions2 = formData;
      })
    },

    async getGradeList () {
      const { data } = await getGrade()
      this.gradeList = data.list
      if (!this.form.class_id) {
        this.form.grade_id = this.form.grade_id || this.gradeList[0].grade_id

      }
      this.addRules()
      this.getSubjectList(this.form)
    },

    getDiffcultyList () {
      this.$http({
        url: '/api/v1/combination/question_difficulty',
        method: 'get',
      }).then(res => {
        this.difficultyList = res.data
        // this.form.question_difficulty = '3'
      })
    },
    getSubjectList () {
      console.log(this.form)
      if (!this.form.grade_id) {
        return
      }
      this.$http({
        url: '/api/v1/public/class_grade_subject',
        method: 'get',
        params: {
          grade_id: this.form.grade_id,
          // class_id: this.form.class_id
        }
      }).then(res => {
        this.subjectList = res.data

        let flag = this.subjectList.some(item => item.subject_id === this.form.questionSubject);
        if (!flag) {
          this.form.questionSubject = ''
        }

      })
    },
    getChapterList () {

      if (this.form.textbook_id == '') {
        return
      }
      this.getsectionList()

    },
    async getBookList () {
      this.form.textbook_id = ''
      this.bookList = []
      // this.form.chapter_id = []
      // this.chapterList = []

      if (this.form.questionSubject == '') {
        return
      }
      const { data } = await getTextBookBySubject({ subject_id: this.form.questionSubject })
      this.bookList = data
    },
    async changeSubject () {

      await this.getDistrictList()
      this.getSourceList()
      // this.getCapabilityMain()
      // this.getsectionList()   //章节
      this.getCapabilitySubject()
      this.getBookList()
    },

    // getSelectList () {
    //   this.$http({
    //     url: '/api/v1/question/choices',
    //     method: 'POST',
    //     data: {
    //       source_id: this.form.source_id,
    //       noCheckpoint: 0,
    //       subject_id: this.form.questionSubject,
    //       district_id: this.form.district_id,
    //       grade_id: this.form.grade_id
    //     }
    //   }).then(res => {
    //     this.categoriesList = res.data.question_categories
    //   })
    // },
    // 考察范围修改时，考点/知识点/章节重置
    changeRange () {
      this.form.check_point_id = []
      this.checkPoint2Options = []
      this.checkPoint3Options = []
      this.form.checkpoints2 = []
      this.form.checkpoints3 = []
      this.knowledge_ids = []
      this.chapter_ids = []
      this.tipList = []
      this.points2TipList = []
      this.points3TipList = []

      let rules = JSON.parse(JSON.stringify(this.rules))
      let chapter_id = [{ required: true, message: '请选择', trigger: 'change' }]
      let knowledge_id = [{ required: true, message: '请选择', trigger: 'change' }]

      rules.chapter_id = chapter_id


      rules.knowledge_id = knowledge_id

      this.$nextTick(() => {
        this.$set(this, 'rules', rules)
      })
    },
    // 考察范围点击时
    handleRange (e) {
      this.changeRange()
      console.log('eeeeee', e);
      console.log('select_which', this.form.select_which);
      if (this.form.select_which == e) {
        this.form.select_which = 0
      } else {
        this.form.select_which = e
      }
    },
    async getSourceList (str) {

      this.$http({
        url: '/api/v1/public/sum_up_source',
        method: 'get',
      }).then(res => {
        this.sourceList = res.data
      })


      if (this.form.questionSubject == '') return


      this.subjectList.forEach(item => {
        if (item.subject_id == this.form.questionSubject) {
          this.show = item.subject_name == '英语' ? true : false
          if (this.show) {
            this.form.questionCategory = ''
          } else {
            this.form.questionType = ''
          }
        }
      });

      // if (str != '题目来源' && str != 'year') {
      //   this.form.source_id = []
      // }
      // let source_id = JSON.parse(JSON.stringify(this.form.source_id))
      // if (source_id.indexOf(0) != -1) {
      //   source_id.splice(this.form.source_id.indexOf(0), 1)
      // }

      await this.$http({
        url: '/api/v1/public/paper_type_category',
        method: 'get',
        params: {
          // district_id: this.form.district_id.join(','),
          // district_id: this.form.district_id,
          subject_id: this.form.questionSubject,
          // source_id: source_id,
          // question_year_begin: this.form.question_year_begin,
          // question_year_end: this.form.question_year_end,
          // grade_id: this.form.grade_id || this.grade_id,
          // class_id: this.form.class_id
        }
      }).then(res => {
        console.log('res', res);

        this.typeList = []
        res.data.question_type_list.forEach(item => {
          if (item.question_type_id != 0) {
            this.typeList.push(item)
          }
        })
        // this.form.questionType = this.typeList[0].question_type_id
        this.categoriesList = res.data.question_category_list
        // if (this.categoriesList && this.categoriesList.length > 0) {
        //   this.form.questionCategory = this.categoriesList[0].question_category_id
        // }
        // this.resultCheckpointsList = res.data.pointLIst.resultCheckpoints
        // this.checkPoint2Options = []
        // this.form.check_point_id = []
        // this.form.checkpoints2 = []
        this.form.questionType = []
        this.form.questionCategory = []
        this.questionNumberDetail = {}
        this.getKnowledgeList()
        // this.getsectionList()   //章节
      })
    },

    async closeTag (index) {
      this.tipList.splice(index, 1)
      let temp = []
      if (this.form.check_point_id && this.form.check_point_id.length > 0) {
        this.form.check_point_id.splice(index, 1)
        await this.checkChange()
        this.changePonit2()
      } else if (this.knowledge_ids && this.knowledge_ids.length > 0) {
        temp = [...this.knowledge_ids]
        temp.splice(index, 1)
        this.knowledge_ids = []
        this.$nextTick(() => {
          if (temp.length > 0) {
            this.$set(this, 'knowledge_ids', temp)
          }
        })
      } else if (this.chapter_ids && this.chapter_ids.length > 0) {
        temp = [...this.chapter_ids]
        temp.splice(index, 1)
        this.chapter_ids = []
        this.$nextTick(() => {
          if (temp.length > 0) {
            this.$set(this, 'chapter_ids', temp)
          }
        })
      }
    },

    closePoint2Tag (index) {
      this.points2TipList.splice(index, 1)
      this.form.checkpoints2.splice(index, 1)
    },
    closePoint3Tag (index) {
      this.points3TipList.splice(index, 1)
      this.form.checkpoints3.splice(index, 1)
    },

    // 已选考点文字显示
    getCkeckPointText () {
      let temp = []
      this.form.check_point_id.forEach(item => {
        temp.push(this.resultCheckpointsList.filter(ele => ele.id == item)[0].text)
      })
      this.$nextTick(() => {
        this.$set(this, 'tipList', temp)
      })
      this.tipContent = '考点'
    },

    // 考点修改
    async checkChange () {
      this.getCkeckPointText()
      // this.form.checkpoints2 = []
      // let check_point_id = this.form.check_point_id.join(',')
      // await this.$http({
      //   url: '/api/v1/combination/second_point',
      //   method: 'post',
      //   data: {
      //     checkpoints: check_point_id
      //   }
      // }).then(res => {
      //   this.checkPoint2Options = res.data.list
      //   if (this.form.checkpoints2) {
      //     let checkpoints2 = []
      //     for (let i of this.checkPoint2Options) {
      //       checkpoints2.push(i.id)
      //     }
      //     this.form.checkpoints2 = this.form.checkpoints2.filter(item => {
      //       return checkpoints2.includes(item)
      //     })
      //   } else {
      //     this.form.checkpoints2 = []
      //   }
      // })
      let res = null
      this.form.check_point_id.map(k => {

        res = this.resultCheckpointsList.filter(item => item.check_point_id == k).map(item => item.child);
      })



      this.checkPoint2Options.push(...res[0])

      if (this.form.checkpoints2) {
        let checkpoints2 = []
        for (let i of this.checkPoint2Options) {
          checkpoints2.push(i.id)
        }
        this.form.checkpoints2 = this.form.checkpoints2.filter(item => {
          return checkpoints2.includes(item)
        })
      } else {
        this.form.checkpoints2 = []
      }
    },
    // 二级考点修改
    changePonit2 () {
      this.points2TipList = []
      let res = null
      this.form.checkpoints2.forEach(item => {
        this.points2TipList.push(this.checkPoint2Options.filter(ele => ele.check_point_id == item)[0].check_point_desc)
        res = this.checkPoint2Options.filter(k => k.check_point_id == item)
      })
      console.log(res[0].child, 'check_point_id')
      if (res[0].child.length > 0) {
        this.checkPoint3Options.push(...res[0].child)

      }
      console.log(this.checkPoint3Options, ' this.checkPoint3Options')


    },
    // 三级考点修改
    changePonit3 () {
      this.points3TipList = []

      this.form.checkpoints3.forEach(item => {
        this.points3TipList.push(this.checkPoint3Options.filter(ele => ele.check_point_id == item)[0].check_point_desc)
      })


    },
    getKnowledgeList () {
      this.knowledge_ids = []
      this.tipList = []
      this.$http({
        url: '/api/v1/question/knowledge',
        method: 'get',
        params: {
          subject_id: this.form.questionSubject
        }
      }).then(res => {
        this.knowledgeList = res.data.list.filter(item => {
          return item.grade_id.split(',').includes(this.form.grade_id.toString());
        });
        this.flatKnowledgeList = getFlatlist(this.knowledgeList, 'child')

      })
    },
    changeKnowledge (value) {

      //为了自定义校验

      this.form.knowledge_id = this.knowledge_ids



      let rules = JSON.parse(JSON.stringify(this.rules))
      let knowledge_id = [{ required: true, message: '请选择', trigger: 'change' }]
      let chapter_id = [{ required: true, message: '请选择', trigger: 'change' }]
      if (this.knowledge_ids && this.knowledge_ids.length > 0) {
        rules.knowledge_id = [{ required: false, message: '', trigger: 'change' }]

      } else {

        rules.knowledge_id = knowledge_id

      }

      this.$nextTick(() => {
        this.$set(this, 'rules', rules)
      })


      // let arrTemp = []
      // value.forEach((item, index) => {
      //   console.log(index, value)
      //   arrTemp[index] = []
      //   item.forEach(ele => {
      //     arrTemp[index].push(this.flatKnowledgeList.filter(i => i.knowledge_id == ele)[0].knowledge_desc)
      //   })
      //   arrTemp[index] = arrTemp[index].join(' / ')
      // })
      let arrTemp = getParentIdList(value, this.flatKnowledgeList, 'knowledge_parent_id', 'knowledge_id', 'knowledge_desc')
      for (let i = 0, len = arrTemp.length; i < len; i++) {
        arrTemp[i] = arrTemp[i].join(' / ')
      }
      console.log(arrTemp, 'aar')
      this.tipList = arrTemp
      this.tipContent = '考点'
    },
    changeChapter (value) {

      //为了自定义校验
      this.form.chapter_id = this.chapter_ids

      let rules = JSON.parse(JSON.stringify(this.rules))
      let chapter_id = [{ required: true, message: '请选择', trigger: 'change' }]
      let knowledge_id = [{ required: true, message: '请选择', trigger: 'change' }]
      if (this.chapter_ids && this.chapter_ids.length > 0) {
        rules.chapter_id = [{ required: false, message: '', trigger: 'change' }]

      } else {
        rules.chapter_id = chapter_id
      }
      this.$nextTick(() => {
        this.$set(this, 'rules', rules)
      })


      let arrtemp = getParentIdList(value, this.flatSectionList, 'chapter_parent_id', 'chapter_id', 'chapter_desc')
      for (let i = 0, len = arrtemp.length; i < len; i++) {
        arrtemp[i] = arrtemp[i].join(' / ')
      }
      console.log(arrtemp, 'aa')
      this.tipList = arrtemp
      this.tipContent = '章节'
    },

    // getSingleList (arr) {
    //   let arr1 = []
    //   for (let item in arr) {
    //     let len = arr[item].length
    //     arr1.push(arr[item][len - 1])
    //     // arr1.push(...arr[item])
    //   }
    //   return arr1
    //   // return [...new Set(arr1)]

    // },

    getsectionList () {
      // console.log(789789789)
      this.chapter_ids = []
      this.tipList = []
      if (!this.form.questionSubject) return
      this.$http({
        url: '/api/v1/combination/textbook_chapter',
        method: 'post',
        data: {
          textbook_id: this.form.textbook_id
        }
      }).then(res => {
        this.sectionList = res.data.list
        if (this.form.grade_id !== 1) {
          this.sectionList = this.sectionList.filter(item => item.grade_id == this.form.grade_id)
        }
        this.flatSectionList = getFlatlist(this.sectionList, 'child')

      })
    },

    // 提交
    toDetails () {

      let form = JSON.parse(JSON.stringify(this.form))
      this.$refs.formRef.validate(val => {
        if (!val) {
          return
        }
        this.loadings = true
        form.capability_ids = []
        // if (form.capability_type1) {
        //   form.capability_ids.push(...form.capability_type1)
        // }
        if (form.capability_id) {
          form.capability_ids.push(...form.capability_id)
        }
        form.capability_ids = new Set(form.capability_ids.flat(Infinity))
        form.capability_ids = Array.from(form.capability_ids);
        // if (form.select_which == '5') {
        //   form.select_which = '4'
        // }

        if (form.select_which == '') {
          form.select_which = '0'
        }
        let submitForm = {
          paper_name: form.paper_name,
          // district_id: form.district_id.join(),
          district_id: form.district_id,
          subject_id: form.questionSubject,
          source_id: form.source_id,
          question_year_begin: form.question_year_begin,
          question_year_end: form.question_year_end,
          questionType: form.questionType,
          questionCategory: form.questionCategory,
          question_num: form.questionNumber,
          show_to: form.show_to,
          paper_time: form.paper_time,
          question_difficulty: form.question_difficulty ? form.question_difficulty.split(',') : '',
          edu_paper_type: form.edu_paper_type,
          given_garde: form.grade_id,
          class_id: form.class_id,
          paper_duration: form.paper_duration,
          end_time: form.end_time,
          edu_paper_correct_type: form.edu_paper_correct_type,
          select_which: form.select_which,
          capability_id: form.capability_ids,
          question_category_ids: form.question_category_ids,
          textbook_id: form.textbook_id,
          select_all: form.select_all ? 'on' : ''

        }
        if (!this.isShow_to) {
          delete submitForm.show_to
        }
        // 判断传知识点还是考点,章节
        // if (submitForm.grade_id == 1 || this.grade_id == 1) {
        //   submitForm.checkpoints = form.check_point_id
        //   submitForm.checkpoints2 = form.checkpoints2 || []
        // } else {
        //   submitForm.knowledge_id = this.knowledge_ids
        // }

        // submitForm.grade_id == 1 || this.grade_id == 1
        if (this.form.select_which == '1') {
          submitForm.knowledge_id = this.knowledge_ids
        } else if (this.form.select_which == '2') {
          submitForm.chapter_id = this.chapter_ids

        }
        console.log('submitForm', this.form.select_which);



        // （除语文英语）有类型时
        if (submitForm.questionType.length != 0) {
          submitForm.question_type_data = []
          let sort = 0
          for (let i in this.questionNumberDetail) {
            if (submitForm.questionType.indexOf(Number(i)) == -1) {
              continue
            }
            submitForm.question_type_data.push({
              "question_type_id": i,
              "num": this.questionNumberDetail[i],
              "sort": sort
            })
            sort++
          }
        }
        // （语文英语）有类别时
        else if (submitForm.questionCategory.length != 0) {
          submitForm.question_category_data = []
          let sort = 0

          for (let i in this.questionNumberDetail) {
            if (submitForm.questionCategory.indexOf(Number(i)) == -1) {
              continue
            }
            submitForm.question_category_data.push({
              "question_category_id": i,
              "num": this.questionNumberDetail[i],
              "sort": sort
            })
            sort++
          }
        }
        submitForm.question_category_ids = form.questionCategory
        // submitForm.isBrackets = true
        this.$emit('validate', submitForm)
        submitForm.module_id = 7
        this.$http({
          url: '/api/v1/public/set_record',
          method: 'post',
          data: submitForm
        }).then(res => {
          // this.$notify({
          //   title: '提示',
          //   message: '操作成功!',
          //   type: 'success'
          // });
        })

      })
    },
  }
}
</script>

<style lang="scss" scoped>
.form_wrap {
  width: 100%;
  .title {
    font-size: 15px;
    font-family: PingFang SC-Regular, PingFang SC;
    margin-left: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #333;
  }
  ::v-deep .el-radio {
    margin-right: 12px;
  }
}
.hide_label {
  ::v-deep .el-radio__label {
    display: none;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
.kaodian {
  ::v-deep .el-select__tags {
    flex-wrap: nowrap;
    // overflow: hidden;
    span {
      display: none;
    }
    // .el-select__tags-text {
    //   max-width: 95px;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   display: inline-block;
    //   vertical-align: bottom;
    // }
  }
}

.zhishidian {
  height: 33px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  border-radius: 4px;
  ::v-deep .el-cascader__tags .el-tag:not(.is-hit) {
    height: 33px;
  }
  ::v-deep .el-cascader__tags .el-tag {
    background-color: #ffffff;
    display: none;
  }
  ::v-deep .el-cascader__tags {
    height: 33px;
  }
  ::v-deep .el-icon-close {
    display: none;
  }
  .el-cascader--medium {
    width: 100%;
  }
  ::v-deep .el-cascader__search-input {
    height: 29px;
  }
  ::v-deep .el-form-item__label {
    font-size: 15px;
  }
}

.question_number {
  position: relative;

  .question_number_select {
    padding: 12px 12px 8px;
    margin-top: 10px;
    background: #fff;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    box-shadow: 0px 2px 12px #ddd;
    position: absolute;
    z-index: 99;
    width: max-content;
    max-width: 350px;
    text-align: right;
  }
  .question_number_arrow {
    z-index: 100;
    position: absolute;
    display: block;
    border-bottom: 6px solid #dfdfdf;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    top: 41px;
    left: 20px;
    &::before {
      position: absolute;
      display: block;
      content: "";
      border-bottom: 6px solid #ffffff;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      top: 1px;
      left: -6px;
    }
  }
}
ol {
  list-style: auto;
  padding: 0 20px;
  line-height: 24px;
  font-size: 15px;
  color: #333;
}
.year_component {
  margin-top: 10px;
  position: absolute;
  z-index: 999;
}
</style>